import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import authSlice from '@/features/auth/redux/authSlice'
import { knwAPI } from '@/utils/api/knw'

export const persistConfig = {
  key: 'root:knw-panel',
  storage,
  version: 1,
  blacklist: [knwAPI.reducerPath],
}
const rootReducer = combineReducers({
  auth: authSlice,
  [knwAPI.reducerPath]: knwAPI.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(knwAPI.middleware),
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

//@ts-ignore
if (window.Cypress) {
  //@ts-ignore
  window.store = store
}
