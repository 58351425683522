import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useStoreRoleMutation } from '@/features/roles/redux/roleAPI'
import { SaveRoleRequestInterface } from '@/features/roles/redux/types'
import { Card } from '@/components'
import { Form } from '@/features/roles/resources/components'

export const RolesCreateScreen = (): React.ReactNode => {
  const { t } = useTranslation(['roles'])
  const [storeRole] = useStoreRoleMutation()
  const navigate = useNavigate()

  const handleSubmit = async (data: SaveRoleRequestInterface) => {
    try {
      await storeRole(data).unwrap()
      toast.success(t('roles:create.success'))
      navigate('/roles')
    } catch (error) {
      // empty block
    }
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <span className={'text-2xl font-medium'}>{t('roles:create.title')}</span>
      <Card>
        <Form onSubmit={handleSubmit} />
      </Card>
    </div>
  )
}
