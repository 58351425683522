import React, { useEffect, useState } from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { PermissionEnum } from '@/features/permissions/redux/enums/permissionEnum'
import { IntegrationInterface } from '@/features/integrations/redux/types'
import {
  useDeleteIntegrationMutation,
  useGetIntegrationsQuery,
} from '@/features/integrations/redux/integrationAPI'
import { ActionStack, baseActionStack, BaseTable } from '@/components'

const columnHelper = createColumnHelper<IntegrationInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => [
  columnHelper.accessor('name', {
    id: 'name',
    header: t('form:labels.name'),
  }),
  columnHelper.accessor('token', {
    id: 'token',
    header: t('form:labels.token'),
  }),
  columnHelper.accessor('ip_addresses', {
    id: 'ip_addresses',
    header: t('form:labels.ip_addresses'),
    cell: ({ row }) => row.original.ip_addresses.join(','),
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onDelete: {
            action: () => onDelete(row.original.id),
            permission: PermissionEnum.INTEGRATION_DELETE,
          },
          onEdit: {
            action: () => onEdit(row.original.id),
            permission: PermissionEnum.INTEGRATION_SAVE,
          },
        })}
      />
    ),
  }),
]

const Table = (): React.ReactNode => {
  const { data: apiData } = useGetIntegrationsQuery()
  const { t } = useTranslation(['form', 'utils', 'integrations'])
  const [data, setData] = useState<IntegrationInterface[]>([])
  const navigate = useNavigate()
  const [deleteIntegration] = useDeleteIntegrationMutation()

  useEffect(() => {
    if (apiData) {
      setData(apiData)
    }
  }, [apiData])

  const handleDelete = async (id: number) => {
    try {
      await deleteIntegration(id).unwrap()
      toast.success(t('integrations:list.deleted'))
    } catch (error) {
      toast.error(t('utils:errors.something_went_wrong'))
    }
  }

  const table = useReactTable({
    columns: columns(t, handleDelete, (id) =>
      navigate(`/integrations/${id}/edit`)
    ),
    data,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className={'flex w-full'}>
      <BaseTable table={table} />
    </div>
  )
}

export { Table }
