import { FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { FormControl } from '@mui/base'
import { MultiValue } from 'react-select'
import { StoreAgreementValidation } from './validations/StoreAgreementValidation'
import { FormInterface, Select2OptionInterface } from '@/app/types'
import {
  AgreementInterface,
  StoreAgreementRequestInterface,
} from '@/features/agreements/redux/types'
import { useGetAgreementGroupsQuery } from '@/features/agreements/redux/agreementAPI'
import { useValidation } from '@/utils/hooks/useValidation'
import {
  AsyncSelect,
  Button,
  Checkbox,
  FormHelperText,
  Label,
  TextInput,
} from '@/components'

const Form: FC<
  FormInterface<StoreAgreementRequestInterface, AgreementInterface>
> = ({ onSubmit }): ReactNode => {
  const { t } = useTranslation(['form', 'validation', 'utils'])
  const { schema, defaultValues } = useValidation(
    new StoreAgreementValidation(),
    t
  )
  const { data: agreementGroups = [] } = useGetAgreementGroupsQuery()
  const [selectedAgreementGroups, setSelectedAgreementGroups] = useState<
    Select2OptionInterface[]
  >([])

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<StoreAgreementRequestInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const handleChangeAgreementGroups = (
    options: MultiValue<Select2OptionInterface>
  ) => {
    if (!options) return

    setSelectedAgreementGroups(options as Select2OptionInterface[])
    setValue(
      'groups',
      options.map(({ value }) => Number(value))
    )
  }

  return (
    <div className={'flex flex-col'}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={'flex flex-col gap-y-6'}
      >
        <div className={'grid grid-cols-3 gap-4'}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl {...field} error={!!error}>
                <TextInput
                  name={field.name}
                  error={!!errors.name}
                  label={t('form:labels.name')}
                />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={'name'}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <div className={'flex flex-col'}>
                <Label label={t('form:labels.agreement_groups')} />
                <AsyncSelect
                  defaultOptions={agreementGroups.map((agreementGroup) => ({
                    label: t(`utils:agreement_groups.${agreementGroup.name}`),
                    value: agreementGroup.id.toString(),
                  }))}
                  isMulti
                  onChange={handleChangeAgreementGroups}
                  value={selectedAgreementGroups}
                  name={field.name}
                  inputId={'agreement_groups'}
                  classNamePrefix={'agreement-group'}
                />
              </div>
            )}
            name={'groups'}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <div className={'flex flex-col gap-y-1'}>
                <Label label={t('form:labels.file')} />
                <input
                  type={'file'}
                  accept={'application/pdf, application/msword, text/plain'}
                  name={field.name}
                  onChange={(e) => {
                    e.target.files
                      ? field.onChange(e.target.files[0])
                      : field.onChange(null)
                  }}
                />
                <FormHelperText
                  error={!!errors.file}
                  message={errors.file?.message}
                />
              </div>
            )}
            name={'file'}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl className={'col-span-3'} {...field} error={!!error}>
                <TextInput
                  multiline
                  name={field.name}
                  rows={5}
                  label={t('form:labels.content')}
                />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={'content'}
            control={control}
          />
          <div className={'flex flex-col gap-y-1'}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error}>
                  <Checkbox
                    label={t('form:labels.required')}
                    checked={field.value}
                    name={field.name}
                    onChange={field.onChange}
                  />
                </FormControl>
              )}
              name={'required'}
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error}>
                  <Checkbox
                    label={t('form:labels.visible')}
                    checked={field.value}
                    name={field.name}
                    onChange={field.onChange}
                  />
                </FormControl>
              )}
              name={'visible'}
              control={control}
            />
          </div>
        </div>
        <div className={'flex pt-4'}>
          <Button variant={'contained'} type={'submit'}>
            {t('form:buttons.save')}
          </Button>
        </div>
      </form>
    </div>
  )
}

export { Form }
