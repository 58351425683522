import { forwardRef } from 'react'
import { Option as BaseOption, OptionProps } from '@mui/base/Option'
import { cn } from '@/utils/helpers/cn'

const Option = forwardRef<HTMLLIElement, OptionProps<number | string>>(
  (props, ref) => {
    const { children, ...rest } = props

    return (
      <BaseOption
        {...rest}
        value={props.value}
        ref={ref}
        slotProps={{
          root: ({ highlighted }) => ({
            className: cn(
              'cursor-default list-none p-2 px-[12px] py-[6px] hover:bg-background-secondary',
              {
                'bg-background-secondary': highlighted,
              }
            ),
          }),
        }}
      >
        <span>{children}</span>
      </BaseOption>
    )
  }
)

Option.displayName = 'Option'

export { Option }
