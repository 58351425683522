import _, { isArray } from 'lodash'
import * as React from 'react'
import { AsyncSelect } from './AsyncSelect'
import { SearchProps } from './AsyncSelect.types'
import { Query } from '@/utils/query'
import { useLazySearchProductQuery } from '@/features/products/redux/productAPI'

const SearchProduct: React.FC<SearchProps> = ({
  defaultValue,
  onChange,
  isMulti,
  query: _query,
}): React.ReactNode => {
  const [searchProduct] = useLazySearchProductQuery()

  const _handleSearchProduct = async (value: string) => {
    let query = new Query()

    if (!_query) {
      query.where('name', value)

      if (defaultValue && !isArray(defaultValue))
        query.whereNotIn('id', [defaultValue.value])
    } else {
      query = _query
    }

    const response = await searchProduct(query.url()).unwrap()

    return response.map((product) => ({
      label: product.name,
      value: product.id.toString(),
    }))
  }

  const handleSearchProduct = _.debounce(_handleSearchProduct, 500)

  return (
    <AsyncSelect
      isMulti={isMulti}
      cacheOptions
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={onChange}
      value={defaultValue}
      loadOptions={handleSearchProduct}
    />
  )
}

export { SearchProduct }
