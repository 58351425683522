import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl } from '@mui/base'
import _ from 'lodash'
import { SaveRoleValidation } from './rules/SaveRoleValidation'
import { FormInterface } from '@/app/types'
import {
  RoleInterface,
  SaveRoleRequestInterface,
} from '@/features/roles/redux/types'
import {
  apiPermissions,
  PermissionEnum,
} from '@/features/permissions/redux/enums/permissionEnum'
import { useValidation } from '@/utils/hooks/useValidation'
import { Button, Checkbox, FormHelperText, TextInput } from '@/components'

export const Form: React.FC<
  FormInterface<SaveRoleRequestInterface, RoleInterface>
> = ({ data, onSubmit }): React.ReactNode => {
  const { t } = useTranslation(['form', 'validation', 'utils'])
  const { schema, defaultValues } = useValidation(new SaveRoleValidation(), t)

  const { control, handleSubmit, getValues, setValue, watch } =
    useForm<SaveRoleRequestInterface>({
      resolver: yupResolver(schema),
      defaultValues,
    })

  const watchPermissions = watch('permissions')

  useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue(
        'permissions',
        data.permissions.map((p) => p.name)
      )
    }
  }, [data])

  const handleChangePermission = (permission: PermissionEnum) => {
    const permissions = getValues('permissions')

    if (permissions.includes(permission)) {
      setValue(
        'permissions',
        permissions.filter((p: PermissionEnum) => p !== permission)
      )
    } else {
      setValue('permissions', [...permissions, permission])
    }
  }

  return (
    <form
      id={'roles-form'}
      onSubmit={handleSubmit(onSubmit)}
      className={'flex flex-col gap-y-6'}
    >
      <div className={'grid 2xl:grid-cols-3 grid-cols-1'}>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl {...field} error={!!error}>
              <TextInput name={field.name} label={t('form:labels.name')} />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
          name={'name'}
          control={control}
        />
      </div>
      <div className={'flex flex-col gap-y-1'}>
        {_.map(apiPermissions, (permission, index) => (
          <Checkbox
            onChange={() => handleChangePermission(permission)}
            key={index}
            checked={watchPermissions.includes(permission)}
            label={t(`utils:permissions.${permission}`)}
          />
        ))}
      </div>
      <div className={'flex pt-6'}>
        <Button variant={'contained'} type={'submit'}>
          {t('form:buttons.save')}
        </Button>
      </div>
    </form>
  )
}
