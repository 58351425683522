import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { usePagination } from '@/utils/hooks/usePagination'
import { Query } from '@/utils/query'
import { useDeleteCategoryMutation } from '@/features/categories/redux/categoryAPI'
import { PostInterface } from '@/features/posts/redux/types'
import { Carbon } from '@/utils/carbon'
import { useGetPostsQuery } from '@/features/posts/redux/postAPI'
import { PermissionEnum } from '@/features/permissions/redux/enums/permissionEnum'
import { ActionStack, baseActionStack, BaseTable } from '@/components'

type Props = {
  query: Query
}

const columnHelper = createColumnHelper<PostInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => [
  columnHelper.accessor('title', {
    id: 'title',
    header: t('form:labels.title'),
    cell: ({ row }) => <span>{row.original.title.slice(0, 25)}</span>,
  }),
  columnHelper.accessor('product.name', {
    id: 'product',
    header: t('form:labels.product_name'),
  }),
  columnHelper.accessor('category.name', {
    id: 'category',
    header: t('form:labels.category_name'),
  }),
  columnHelper.accessor('user.name', {
    id: 'user',
    header: t('form:labels.username'),
  }),
  columnHelper.accessor('price', {
    id: 'price',
    header: t('form:labels.price'),
    cell: ({ row }) => (
      <span>
        {Intl.NumberFormat('pl', {
          style: 'currency',
          currency: 'PLN',
        }).format(row.original.price)}
      </span>
    ),
  }),
  columnHelper.accessor('quantity', {
    id: 'quantity',
    header: t('form:labels.quantity'),
  }),
  columnHelper.accessor('unit', {
    id: 'unit',
    header: t('form:labels.unit'),
    cell: ({ row }) => <span>{t(`utils:units.${row.original.unit}`)}</span>,
  }),
  columnHelper.accessor('expired_at', {
    id: 'expired_at',
    header: t('form:labels.expired_at'),
    cell: ({ row }) => (
      <span>
        {row.original.expired_at
          ? new Carbon()
              .parse(row.original.expired_at)
              .format('dd.MM.yyyy HH:mm')
          : '-'}
      </span>
    ),
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onDelete: {
            action: () => onDelete(row.original.id),
            permission: PermissionEnum.POST_DELETE,
          },
          onEdit: {
            action: () => onEdit(row.original.id),
            permission: PermissionEnum.POST_SAVE,
          },
        })}
      />
    ),
  }),
]

export const Table: FC<Props> = ({ query: baseQuery }): ReactNode => {
  const [pagination, setPagination] = usePagination()
  const [data, setData] = useState<PostInterface[]>([])
  const query = useMemo(
    () =>
      baseQuery
        .limit(pagination.pageSize)
        .page(pagination.pageIndex)
        .includes('product', 'category', 'user'),
    [baseQuery, pagination.pageIndex, pagination.pageSize]
  )
  const { data: apiData } = useGetPostsQuery(query.url())
  const { t } = useTranslation(['form', 'utils', 'posts'])
  const navigate = useNavigate()
  const [deleteCategory] = useDeleteCategoryMutation()

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const handleDelete = async (id: number) => {
    try {
      await deleteCategory(id).unwrap()
      toast.success(t('posts:list.deleted'))
    } catch (error) {
      toast.error(t('utils:errors.something_went_wrong'))
    }
  }

  const table = useReactTable({
    columns: columns(t, handleDelete, (id) => navigate(`/posts/${id}/edit`)),
    data: data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return (
    <div className={'flex w-full'}>
      <BaseTable table={table} pagination={apiData?.pagination} />
    </div>
  )
}
