import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Table } from './components'
import { PermissionEnum } from '@/features/permissions/redux/enums/permissionEnum'
import { usePermissions } from '@/utils/hooks/usePermission'
import { ButtonLink, Card } from '@/components'

export const AgreementListScreen = (): React.ReactNode => {
  const { t } = useTranslation(['agreements', 'form'])
  const { hasPermission } = usePermissions()

  return (
    <div className={'flex flex-col p-8 gap-y-4'}>
      <div className={'flex flex-row-reverse'}>
        {hasPermission(PermissionEnum.AGREEMENT_SAVE) && (
          <ButtonLink
            variant={'contained'}
            className={'flex items-center gap-x-2'}
            to={'/agreements/create'}
          >
            <FontAwesomeIcon icon={faPlus} className={'text-white'} />
            <span>{t('agreements:list.create')}</span>
          </ButtonLink>
        )}
      </div>
      <Card noPadding>
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'border-b border-neutral-600 px-6 py-4 text-l font-medium'
            }
          >
            <span>{t('agreements:list.title')}</span>
          </div>
          <Table />
        </div>
      </Card>
    </div>
  )
}
