import React, { useEffect, useMemo, useState } from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { Query } from '@/utils/query'
import {
  useDeleteUserMutation,
  useGetUsersQuery,
} from '@/features/users/redux/userAPI'
import { UserInterface } from '@/features/users/redux/types'
import { Carbon } from '@/utils/carbon'
import { useGetMeQuery } from '@/features/auth/redux/authAPI'
import { PermissionEnum } from '@/features/permissions/redux/enums/permissionEnum'
import { ActionStack, baseActionStack, BaseTable } from '@/components'
import { usePagination } from '@/utils/hooks/usePagination'

type Props = {
  query: Query
}

const columnHelper = createColumnHelper<UserInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => [
  columnHelper.accessor('name', {
    id: 'name',
    header: t('form:labels.username'),
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: t('form:labels.email'),
  }),
  columnHelper.accessor('email_verified_at', {
    header: t('form:labels.email_verified_at'),
    id: 'created_at',
    cell: ({ row }) =>
      row.original.email_verified_at &&
      new Carbon()
        .parse(row.original.email_verified_at)
        .format('dd.MM.yyyy HH:mm:ss'),
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onDelete: {
            permission: PermissionEnum.USER_DELETE,
            action: () => onDelete(row.original.id),
          },
          onEdit: {
            permission: PermissionEnum.USER_SAVE,
            action: () => onEdit(row.original.id),
          },
        })}
      />
    ),
  }),
]

export const Table: React.FC<Props> = (props): React.ReactNode => {
  const [pagination, setPagination] = usePagination()
  const [data, setData] = useState<UserInterface[]>([])
  const query = useMemo(
    () => props.query.limit(pagination.pageSize).page(pagination.pageIndex),
    [props.query, pagination.pageIndex, pagination.pageSize]
  )
  const { data: apiData } = useGetUsersQuery(query.url())
  const { t } = useTranslation(['form', 'utils'])
  const { data: currentUser } = useGetMeQuery()
  const [deleteUser] = useDeleteUserMutation()
  const navigate = useNavigate()

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const handleDelete = async (id: number) => {
    if (currentUser?.id !== id) {
      try {
        await deleteUser(id).unwrap()
        toast.success(t('users:list.deleted'))
        navigate('/users')
      } catch (error) {
        // block empty
      }
    } else {
      toast.error(t('users:list.can_not_delete_yourself'))
    }
  }

  const table = useReactTable({
    columns: columns(t, handleDelete, (id) => navigate(`/users/${id}/edit`)),
    data: data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return (
    <div className={'flex w-full'}>
      <BaseTable table={table} pagination={apiData?.pagination} />
    </div>
  )
}
