import { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, UseFormReturn } from 'react-hook-form'
import _ from 'lodash'
import { SingleValue } from 'react-select'
import { FormControl } from '@mui/base'
import { associateValues } from './helper'
import { Carbon } from '@/utils/carbon'
import { Select2OptionInterface } from '@/app/types'
import { UnitEnum } from '@/app/enums/unitEnum'
import {
  PostInterface,
  UpdatePostRequestInterface,
} from '@/features/posts/redux/types'
import {
  Card,
  Checkbox,
  FormHelperText,
  Label,
  Option,
  SearchCategory,
  SearchProduct,
  Select,
  TextInput,
} from '@/components'

type Props = {
  data?: PostInterface
  form: UseFormReturn<UpdatePostRequestInterface>
}

const BasicDataForm: FC<Props> = ({
  data,
  form: {
    setValue,
    formState: { errors },
    control,
  },
}): ReactNode => {
  const { t } = useTranslation(['posts'])
  const [selectedCategory, setSelectedCategory] =
    useState<Select2OptionInterface>()
  const [selectedProduct, setSelectedProduct] =
    useState<Select2OptionInterface>()

  useEffect(() => {
    if (data && data.category && data.product) {
      associateValues(data, setValue)
      setSelectedCategory({
        label: data.category.name,
        value: data.category.id.toString(),
      })
      setSelectedProduct({
        label: data.product.name,
        value: data.product.id.toString(),
      })
    }
  }, [data, setValue])

  if (!data) {
    return <span></span>
  }

  const handleCategoryChange = (
    value: SingleValue<Select2OptionInterface>
  ): void => {
    if (value) {
      setSelectedCategory(value as Select2OptionInterface)
      setValue('category_id', parseInt(value.value))
    }
  }

  const handleProductChange = (
    value: SingleValue<Select2OptionInterface>
  ): void => {
    if (value) {
      setSelectedProduct(value as Select2OptionInterface)
      setValue('product_id', parseInt(value.value))
    }
  }

  return (
    <Card>
      <span className={'font-medium'}>{t('posts:edit.basic.title')}</span>
      <div className={'flex flex-col gap-y-4 pt-6'}>
        <div className={'grid lg:grid-cols-3 gap-4'}>
          <div className={'flex flex-col'}>
            <Label label={t('form:labels.created_at')} />
            <span>
              {new Carbon().parse(data.created_at).format('dd.MM.yyyy HH:mm')}
            </span>
          </div>
          <div className={'flex flex-col'}>
            <Label label={t('form:labels.expired_at')} />
            <span>
              {data.expired_at
                ? new Carbon().parse(data.expired_at).format('dd.MM.yyyy HH:mm')
                : '-'}
            </span>
          </div>
          <div className={'flex flex-col gap-y-1'}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error}>
                  <Checkbox
                    onChange={field.onChange}
                    checked={field.value}
                    label={t('form:labels.is_prow_wps')}
                  />
                </FormControl>
              )}
              name={'is_prow_wps'}
              control={control}
            />
          </div>
          <div className={'flex flex-col gap-y-3'}>
            <div className={'flex flex-col'}>
              <Label label={t('form:labels.status')} />
              <span>{t(`posts:statuses.${data.status}`)}</span>
            </div>
          </div>
        </div>
        <div className={'grid lg:grid-cols-2 2xl:grid-cols-3 gap-4'}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error}>
                <TextInput
                  type={'number'}
                  label={t('form:labels.price')}
                  inputProps={{
                    step: '0.01',
                  }}
                  name={field.name}
                />
              </FormControl>
            )}
            name={'price'}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error}>
                <Select
                  value={field.value}
                  onChange={(_, value) => field.onChange(value)}
                  label={t('form:labels.unit')}
                  placeholder={t('form:labels.select_unit')}
                >
                  {_.map(UnitEnum, (value, key) => (
                    <Option value={value} key={key}>
                      {t(`utils:units.${value}`)}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            )}
            name={'unit'}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error}>
                <TextInput
                  type={'number'}
                  label={t('form:labels.quantity')}
                  inputProps={{
                    step: '0.01',
                  }}
                  name={field.name}
                />
              </FormControl>
            )}
            name={'quantity'}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error}>
                <TextInput
                  label={t('form:labels.production_place')}
                  name={field.name}
                />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={'production_place'}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error}>
                <TextInput
                  label={t('form:labels.harvest_date')}
                  name={field.name}
                  type={'date'}
                />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={'harvest_date'}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error}>
                <TextInput
                  label={t('form:labels.expired_at')}
                  name={field.name}
                  type={'date'}
                />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={'expired_at'}
            control={control}
          />
          <div className={'flex flex-col gap-y-2 lg:col-span-2 2xl:col-span-3'}>
            <Controller
              render={() => (
                <SearchCategory
                  onChange={handleCategoryChange}
                  isMulti={false}
                  defaultValue={selectedCategory}
                />
              )}
              name={'category_id'}
              control={control}
            />
          </div>
          <div className={'col-span-2 2xl:col-span-3 flex flex-col'}>
            <Label label={t('form:labels.product')} />
            <Controller
              render={() => (
                <SearchProduct
                  onChange={handleProductChange}
                  isMulti={false}
                  defaultValue={selectedProduct}
                />
              )}
              name={'product_id'}
              control={control}
            />
          </div>
        </div>
        <div className={'flex flex-col gap-y-4'}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error}>
                <TextInput label={t('form:labels.title')} name={field.name} />
                <FormHelperText message={errors.title?.message} />
              </FormControl>
            )}
            name={'title'}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error}>
                <TextInput
                  label={t('form:labels.description')}
                  multiline
                  rows={5}
                  name={field.name}
                />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={'description'}
            control={control}
          />
        </div>
      </div>
    </Card>
  )
}

export { BasicDataForm }
