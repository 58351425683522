import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  useDeleteRoleMutation,
  useGetRolesQuery,
} from '@/features/roles/redux/roleAPI'
import { RoleInterface } from '@/features/roles/redux/types'
import { Query } from '@/utils/query'
import { PermissionEnum } from '@/features/permissions/redux/enums/permissionEnum'
import { usePagination } from '@/utils/hooks/usePagination'
import { ActionStack, baseActionStack, BaseTable } from '@/components'

export const Table = (): React.ReactNode => {
  const { t } = useTranslation(['form', 'roles'])
  const navigate = useNavigate()
  const [{ pageIndex, pageSize }, setPagination] = usePagination()
  const query = useMemo(() => new Query().page(pageIndex), [pageIndex])
  const { data: apiData } = useGetRolesQuery(query.url())
  const [data, setData] = useState<RoleInterface[]>([])
  const [deleteRole] = useDeleteRoleMutation()

  useEffect(() => {
    if (apiData) setData(apiData.data)
  }, [apiData])

  const onDelete = async (id: number) => {
    try {
      await deleteRole(id).unwrap()
      toast.success(t('roles:list.deleted'))
    } catch (error) {
      toast.error(t('utils:errors.something_went_wrong'))
    }
  }

  const table = useReactTable({
    columns: columns(t, onDelete, (id) => navigate(`/roles/${id}/edit`)),
    getCoreRowModel: getCoreRowModel(),
    data,
    manualPagination: true,
    state: {
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    onPaginationChange: setPagination,
  })

  return <BaseTable pagination={apiData?.pagination} table={table} />
}

const columnBuilder = createColumnHelper<RoleInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => [
  columnBuilder.accessor('name', {
    id: 'type',
    header: t('form:labels.name'),
  }),
  columnBuilder.display({
    id: 'actions',
    header: t('form:labels.actions'),
    meta: {
      columnClassName: 'text-right pr-8',
    },
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onDelete: {
            action: () => onDelete(row.original.id),
            permission: PermissionEnum.ROLE_DELETE,
          },
          onEdit: {
            action: () => onEdit(row.original.id),
            permission: PermissionEnum.ROLE_SAVE,
          },
        })}
      />
    ),
  }),
]
