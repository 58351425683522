import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { serialize } from 'object-to-formdata'
import { Form } from './components'
import { useStoreAgreementMutation } from '@/features/agreements/redux/agreementAPI'
import { StoreAgreementRequestInterface } from '@/features/agreements/redux/types'
import { Card } from '@/components'

const AgreementCreateScreen = (): React.ReactNode => {
  const { t } = useTranslation(['agreements'])
  const [storeAgreement] = useStoreAgreementMutation()
  const navigate = useNavigate()

  const onSubmit = async (data: StoreAgreementRequestInterface) => {
    const formData = serialize(data, {
      indices: true,
      booleansAsIntegers: true,
      nullsAsUndefineds: true,
    })

    try {
      await storeAgreement(formData).unwrap()
      toast.success(t('agreements:create.success'))
      navigate('/agreements')
    } catch (error) {
      toast.error(t('utils:errors.something_went_wrong'))
    }
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <span className={'text-2xl font-medium'}>
        {t('agreements:create.title')}
      </span>
      <Card>
        <Form onSubmit={onSubmit} />
      </Card>
    </div>
  )
}

export { AgreementCreateScreen }
