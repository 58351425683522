import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  useGetProductQuery,
  useUpdateProductMutation,
} from '@/features/products/redux/productAPI'
import { SaveProductPropsInterface } from '@/features/products/redux/types'
import { Card } from '@/components'
import { Form } from '@/features/products/resources/components'

export const ProductEditScreen = (): React.ReactNode => {
  const { t } = useTranslation(['products'])
  const { id } = useParams<{ id: string }>() as { id: string }
  const [updateProduct] = useUpdateProductMutation()
  const { data } = useGetProductQuery(id)
  const navigate = useNavigate()

  const onSubmit = async (data: SaveProductPropsInterface) => {
    try {
      await updateProduct({ id: id, body: data }).unwrap()
      toast.success(t('products:edit.success'))
      navigate('/products')
    } catch (error) {
      // empty block
    }
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <span className={'text-2xl font-medium'}>
        {t('categories:edit.title')}
      </span>
      <Card>
        <Form data={data} onSubmit={onSubmit} />
      </Card>
    </div>
  )
}
