import React, { RefAttributes } from 'react'
import BaseAsyncSelect, { AsyncProps } from 'react-select/async'
import { GroupBase } from 'react-select'
import Select from 'react-select/base'

function AsyncSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: AsyncProps<Option, IsMulti, Group> &
    RefAttributes<Select<Option, IsMulti, Group>>
) {
  return (
    <BaseAsyncSelect
      classNames={{
        control: () =>
          '!bg-white flex items-center !border !border-neutral-700 !rounded-md !focus:ring-1 !focus:ring-primary !focus:border-transparent',
      }}
      {...props}
    />
  )
}

export { AsyncSelect }
