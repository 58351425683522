import { addDays, format, subDays, subMonths, subYears } from 'date-fns'

class Carbon {
  private date: Date = new Date()

  now(): Date {
    return this.date
  }

  addDays(days: number): this {
    this.date = addDays(this.date, days)

    return this
  }

  subDays(days: number): this {
    this.date = subDays(this.date, days)

    return this
  }

  subMonths(months: number): this {
    this.date = subMonths(this.date, months)

    return this
  }

  subYears(years: number): this {
    this.date = subYears(this.date, years)

    return this
  }

  format(formatDate: string): string {
    return format(this.date, formatDate)
  }

  getDate(): Date {
    return this.date
  }

  parse(date: string | Date): this {
    if (date instanceof Date) {
      this.date = date
    } else {
      try {
        this.date = new Date(date)
      } catch (error) {
        throw new Error('Invalid date')
      }
    }

    return this
  }
}

export { Carbon }
