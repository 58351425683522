import { FC, memo, ReactNode, useEffect } from 'react'
import { Select2OptionInterface } from '@/app/types'
import { useTranslation } from 'react-i18next'
import { Controller, UseFormReturn } from 'react-hook-form'
import { FormControl } from '@mui/base'
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { LatLngTuple } from 'leaflet'
import _ from 'lodash'
import { SingleValue } from 'react-select'
import { toast } from 'react-toastify'
import {
  PostInterface,
  UpdatePostRequestInterface,
} from '@/features/posts/redux/types'
import { useLazySearchCityQuery } from '@/utils/api/nominatim'
import { AsyncSelect, Card, FormHelperText, TextInput } from '@/components'

type Props = {
  data?: PostInterface
  form: UseFormReturn<UpdatePostRequestInterface>
}

const LocationForm: FC<Props> = ({
  data,
  form: { control, setValue, watch },
}): ReactNode => {
  const { t } = useTranslation(['posts'])
  const [searchCities, { data: cities = [] }] = useLazySearchCityQuery()
  const watchLocation = watch('location')

  useEffect(() => {
    if (data) {
      setValue('location', {
        lat: data.location.lat,
        lng: data.location.lng,
      })
      setValue('location_name', data.location_name)
      setValue('address', data.address)
    }
  }, [data])

  if (!data) {
    return <span></span>
  }

  const handleChangeLocation = (coordinates: LatLngTuple) => {
    setValue('location', {
      lat: coordinates[0],
      lng: coordinates[1],
    })
  }

  const _handleSearchLocation = async (value: string) => {
    try {
      const response = await searchCities(value).unwrap()

      return response.map((city) => ({
        label: `${city.address.country}, ${city.address.city}, ${city.address.state}`,
        value: city.place_id.toString(),
      }))
    } catch (error) {
      toast.error(t('utils:errors.something_went_wrong'))
    }

    return []
  }

  const handleSearchLocation = _.debounce(_handleSearchLocation, 500)

  const handleSelectLocation = (
    option: SingleValue<Select2OptionInterface>
  ) => {
    if (!option) return

    const placeId = Number(option.value)
    const selectedCity = cities.find((city) => city.place_id === placeId)

    if (selectedCity) {
      setValue('location', {
        lat: selectedCity.lat,
        lng: selectedCity.lon,
      })
      setValue('location_name', option.label)
    }
  }

  return (
    <Card className={'flex flex-col gap-6'}>
      <span className={'font-medium'}>{t('posts:edit.address.title')}</span>
      <div className={'flex flex-col gap-y-4'}>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl {...field} error={!!error} className={'z-[9999]'}>
              <AsyncSelect
                onChange={handleSelectLocation}
                cacheOptions
                loadOptions={handleSearchLocation}
                value={{ label: field.value, value: field.value }}
              />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
          name={'location_name'}
          control={control}
        />
        <MapContainer
          center={[watchLocation?.lat, watchLocation?.lng]}
          zoom={13}
          scrollWheelZoom={false}
          className={'min-h-[300px]'}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          <LocationMarker
            onChange={handleChangeLocation}
            coordinates={[watchLocation?.lat, watchLocation?.lng]}
          />
        </MapContainer>
        <div className={'grid grid-cols-3 gap-4'}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl {...field} error={!!error}>
                <TextInput label={t('form:labels.address')} name={field.name} />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={'address.address'}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl {...field} error={!!error}>
                <TextInput label={t('form:labels.city')} name={field.name} />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={'address.city'}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl {...field} error={!!error}>
                <TextInput
                  label={t('form:labels.zip_code')}
                  name={field.name}
                />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={'address.zip_code'}
            control={control}
          />
        </div>
      </div>
    </Card>
  )
}

type LocationProps = {
  coordinates: LatLngTuple
  onChange: (coordinates: LatLngTuple) => void
}

const LocationMarker = memo(function LocationMarker({
  coordinates,
  onChange,
}: LocationProps): ReactNode {
  const map = useMapEvents({
    click(e) {
      onChange([e.latlng.lat, e.latlng.lng])
    },
  })

  useEffect(() => {
    map.panTo(coordinates)
  }, [coordinates, map])

  return (
    <Marker position={coordinates}>
      <Popup>You are here</Popup>
    </Marker>
  )
})

export { LocationForm }
