import { ForwardedRef, forwardRef } from 'react'
import { Link } from 'react-router-dom'
import { ButtonLinkInterface } from './ButtonLink.types'
import { button } from './Button.types'

const ButtonLink = forwardRef(
  (props: ButtonLinkInterface, ref: ForwardedRef<HTMLAnchorElement>) => {
    const { children, className, variant, to, size, ...rest } = props

    return (
      <div className={'flex'}>
        <Link
          ref={ref}
          to={to}
          className={button({
            className,
            variant,
            size,
          })}
          {...rest}
        >
          {children}
        </Link>
      </div>
    )
  }
)

ButtonLink.displayName = 'ButtonLink'

export { ButtonLink }
