import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import { FormControl } from '@mui/base'
import { SaveProductValidation } from './rules/SaveProductValidation'
import { Select2OptionInterface } from '@/app/types'
import { Query } from '@/utils/query'
import { useLazySearchCategoriesQuery } from '@/features/categories/redux/categoryAPI'
import {
  ProductInterface,
  SaveProductPropsInterface,
} from '@/features/products/redux/types'
import { useValidation } from '@/utils/hooks/useValidation'
import {
  AsyncSelect,
  Button,
  FormHelperText,
  Label,
  TextInput,
} from '@/components'

type Props = {
  data?: ProductInterface
  onSubmit: (data: SaveProductPropsInterface) => void
}

export const Form: React.FC<Props> = ({ data, onSubmit }): React.ReactNode => {
  const { t } = useTranslation(['form', 'validation'])
  const { schema, defaultValues } = useValidation(
    new SaveProductValidation(),
    t
  )
  const [defaultCategory, setDefaultCategory] =
    useState<Select2OptionInterface>()
  const [searchCategories] = useLazySearchCategoriesQuery()

  const { control, handleSubmit, setValue } =
    useForm<SaveProductPropsInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })

  useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue('category_id', data.category_id)

      if (data.category) {
        setDefaultCategory({
          value: data.category.id.toString(),
          label: data.category.name,
        })
      }
    }
  }, [data])

  const _searchCategory = async (value: string) => {
    const response = await searchCategories(
      new Query().where('name', value).url()
    ).unwrap()

    return response.map((category) => ({
      value: category.id.toString(),
      label: category.name,
    }))
  }

  const searchCategory = _.debounce(_searchCategory, 500)

  return (
    <div className={'flex flex-col gap-y-6'}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={'grid grid-cols-3 2xl:grid-cols-4 gap-4'}
      >
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl {...field} error={!!error}>
              <TextInput name={field.name} label={t('form:labels.name')} />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
          name={'name'}
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl error={!!error} className={'flex flex-col'}>
              <Label label={t('form:labels.category')} />
              <AsyncSelect
                inputId={'category_id'}
                placeholder={t('form:placeholders.type_to_search_category')}
                cacheOptions
                value={defaultCategory}
                name={field.name}
                classNamePrefix={'category-id'}
                loadOptions={searchCategory}
                onChange={(value) => field.onChange(value?.value)}
              />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
          name={'category_id'}
          control={control}
        />
      </form>
      <div className={'flex'}>
        <Button variant={'contained'} type={'submit'}>
          {t('form:buttons.save')}
        </Button>
      </div>
    </div>
  )
}
