import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useStoreProductMutation } from '@/features/products/redux/productAPI'
import { SaveProductPropsInterface } from '@/features/products/redux/types'
import { Card } from '@/components'
import { Form } from '@/features/products/resources/components'

export const ProductCreateScreen = (): React.ReactNode => {
  const { t } = useTranslation(['products'])
  const [storeProduct] = useStoreProductMutation()
  const navigate = useNavigate()

  const onSubmit = async (data: SaveProductPropsInterface) => {
    try {
      await storeProduct(data).unwrap()
      toast.success(t('products:create.success'))
      navigate('/products')
    } catch (error) {
      // empty block
    }
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <span className={'text-2xl font-medium'}>
        {t('products:create.title')}
      </span>
      <Card>
        <Form onSubmit={onSubmit} />
      </Card>
    </div>
  )
}
