import { TFunction } from 'i18next'
import * as yup from 'yup'
import { UpdateCategoryPropsInterface } from '@/features/categories/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/useValidation.types'

class UpdateCategoryValidation
  implements ValidationInterface<UpdateCategoryPropsInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<UpdateCategoryPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      name: yup.string().required(t('validation:required')),
      parent_id: yup.number().nullable(),
      icon: yup.mixed<File>().nullable(),
    })
  }

  defaultValues(): UpdateCategoryPropsInterface {
    return {
      name: '',
      parent_id: null,
      icon: null,
    }
  }
}

export { UpdateCategoryValidation }
