import { Fragment, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl } from '@mui/base'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { ForgotPasswordValidation } from '../validations/ForgotPasswordValidation'
import { useAppDispatch } from '@/app/hooks'
import { forgotPasswordMutation } from '@/features/auth/redux/authAPI'
import { ForgotPasswordPropsInterface } from '@/features/auth/redux/types'
import { Button, FormHelperText, TextInput } from '@/components'
import { useValidation } from '@/utils/hooks/useValidation'

export const ForgotPasswordScreen = (): ReactNode => {
  const { t } = useTranslation(['auth', 'form', 'validation'])
  const { schema, defaultValues } = useValidation(
    new ForgotPasswordValidation(),
    t
  )
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleForgotPassword = async (data: ForgotPasswordPropsInterface) => {
    try {
      await dispatch(forgotPasswordMutation(data)).unwrap()
      toast.success(t('auth:forgot_password.success'))
      navigate('/login')
    } catch (error) {
      /* empty */
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  return (
    <Fragment>
      <div className={'flex flex-col gap-y-6'}>
        <Controller
          name={'email'}
          control={control}
          render={({ field }) => (
            <FormControl {...field} error={!!errors.email}>
              <TextInput
                name={field.name}
                label={t('form:labels.email')}
                type={'email'}
                placeholder={t('form:placeholders.email')}
              />
              <FormHelperText message={errors.email?.message} />
            </FormControl>
          )}
        />
        <Button
          variant={'contained'}
          onClick={handleSubmit(handleForgotPassword)}
        >
          {t('auth:forgot_password.button')}
        </Button>
      </div>
    </Fragment>
  )
}
