export enum PermissionEnum {
  USER_VIEW = 'users.view',
  USER_SAVE = 'users.save',
  USER_DELETE = 'users.delete',
  CATEGORY_DELETE = 'categories.delete',
  CATEGORY_SAVE = 'categories.save',
  CATEGORY_VIEW = 'categories.view',
  PRODUCT_SAVE = 'products.save',
  PRODUCT_VIEW = 'products.view',
  PRODUCT_DELETE = 'products.delete',
  ROLE_SAVE = 'roles.save',
  ROLE_VIEW = 'roles.view',
  ROLE_DELETE = 'roles.delete',
  AGREEMENT_VIEW = 'agreements.view',
  AGREEMENT_SAVE = 'agreements.save',
  AGREEMENT_DELETE = 'agreements.delete',
  POST_VIEW = 'posts.view',
  POST_SAVE = 'posts.save',
  POST_DELETE = 'posts.delete',
  INTEGRATION_VIEW = 'integrations.view',
  INTEGRATION_SAVE = 'integrations.save',
  INTEGRATION_DELETE = 'integrations.delete',
}

export const apiPermissions = [
  PermissionEnum.USER_VIEW,
  PermissionEnum.USER_SAVE,
  PermissionEnum.USER_DELETE,
  PermissionEnum.CATEGORY_DELETE,
  PermissionEnum.CATEGORY_SAVE,
  PermissionEnum.CATEGORY_VIEW,
  PermissionEnum.PRODUCT_SAVE,
  PermissionEnum.PRODUCT_VIEW,
  PermissionEnum.PRODUCT_DELETE,
  PermissionEnum.ROLE_SAVE,
  PermissionEnum.ROLE_VIEW,
  PermissionEnum.ROLE_DELETE,
  PermissionEnum.AGREEMENT_VIEW,
  PermissionEnum.AGREEMENT_SAVE,
  PermissionEnum.AGREEMENT_DELETE,
  PermissionEnum.POST_VIEW,
  PermissionEnum.POST_SAVE,
  PermissionEnum.POST_DELETE,
]

export const integrationPermissions = [
  PermissionEnum.USER_SAVE,
  PermissionEnum.POST_SAVE,
]
