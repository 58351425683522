import { Fragment, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl } from '@mui/base'
import { toast } from 'react-toastify'
import { LoginValidation } from '../validations/LoginValidation'
import { useAppDispatch } from '@/app/hooks'
import { LogInPropsInterface } from '@/features/auth/redux/types'
import { loginMutation } from '@/features/auth/redux/authAPI'
import { Button, FormHelperText, TextInput } from '@/components'
import { useValidation } from '@/utils/hooks/useValidation'

const LoginScreen = (): ReactNode => {
  const { t } = useTranslation(['auth', 'form', 'validation'])
  const { schema, defaultValues } = useValidation(new LoginValidation(), t)
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = async (data: LogInPropsInterface) => {
    try {
      await dispatch(loginMutation(data)).unwrap()
      toast.success(t('auth:login.success'))
    } catch (error) {
      toast.error(t('auth:login.error'))
    }
  }

  return (
    <Fragment>
      <div className={'flex flex-col gap-y-6'}>
        <Controller
          name={'email'}
          control={control}
          render={({ field }) => (
            <FormControl {...field} error={!!errors.email}>
              <TextInput
                name={field.name}
                label={t('form:labels.email')}
                type={'email'}
                placeholder={t('form:placeholders.email')}
              />
              <FormHelperText message={errors.email?.message} />
            </FormControl>
          )}
        />
        <Controller
          name={'password'}
          control={control}
          render={({ field }) => (
            <FormControl {...field} error={!!errors.password}>
              <TextInput
                name={field.name}
                label={t('form:labels.password')}
                type={'password'}
                placeholder={t('form:placeholders.password')}
              />
              <FormHelperText message={errors.password?.message} />
            </FormControl>
          )}
        />
        <Button variant={'contained'} onClick={handleSubmit(onSubmit)}>
          {t('auth:login.button')}
        </Button>
      </div>
    </Fragment>
  )
}

export { LoginScreen }
