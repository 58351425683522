import React, { useEffect, useMemo, useState } from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { Query } from '@/utils/query'
import { PermissionEnum } from '@/features/permissions/redux/enums/permissionEnum'
import { ResponseCodeEnum } from '@/app/types'
import { ProductInterface } from '@/features/products/redux/types'
import {
  useDeleteProductMutation,
  useGetProductsQuery,
} from '@/features/products/redux/productAPI'
import { usePagination } from '@/utils/hooks/usePagination'
import { isFetchBaseQueryErrorWithCode } from '@/utils/api/rtkHelper'
import { ActionStack, baseActionStack, BaseTable } from '@/components'

type Props = {
  query: Query
}

const columnHelper = createColumnHelper<ProductInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => [
  columnHelper.accessor('name', {
    id: 'name',
    header: t('form:labels.name'),
  }),
  columnHelper.accessor('category.name', {
    id: 'category',
    header: t('form:labels.category'),
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => (
      <ActionStack
        actions={baseActionStack({
          onDelete: {
            action: () => onDelete(row.original.id),
            permission: PermissionEnum.PRODUCT_DELETE,
          },
          onEdit: {
            action: () => onEdit(row.original.id),
            permission: PermissionEnum.PRODUCT_SAVE,
          },
        })}
      />
    ),
  }),
]

export const Table: React.FC<Props> = (props): React.ReactNode => {
  const [pagination, setPagination] = usePagination()
  const [data, setData] = useState<ProductInterface[]>([])
  const query = useMemo(
    () => props.query.limit(pagination.pageSize).page(pagination.pageIndex),
    [props.query, pagination.pageIndex, pagination.pageSize]
  )
  const { data: apiData } = useGetProductsQuery(query.url())
  const { t } = useTranslation(['form', 'utils'])
  const navigate = useNavigate()
  const [deleteProduct] = useDeleteProductMutation()

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const handleDelete = async (id: number) => {
    try {
      await deleteProduct(id).unwrap()
      toast.success(t('products:list.deleted'))
    } catch (error) {
      if (
        isFetchBaseQueryErrorWithCode(error) &&
        error.data.code === ResponseCodeEnum.PRODUCT_ASSIGNED_TO_POST
      ) {
        toast.error(t('products:list.assigned_to_post'))
      }
    }
  }

  const table = useReactTable({
    columns: columns(t, handleDelete, (id) => navigate(`/products/${id}/edit`)),
    data: data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return (
    <div className={'flex w-full'}>
      <BaseTable table={table} pagination={apiData?.pagination} />
    </div>
  )
}
