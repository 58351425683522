import { CssTransition, PopupContext, SelectListboxSlotProps } from '@mui/base'
import React, { ForwardedRef, forwardRef, useContext } from 'react'

const AnimatedListBox = forwardRef(function AnimatedListbox<
  Value extends string | number,
  Multiple extends boolean
>(
  props: SelectListboxSlotProps<Value, Multiple>,
  ref: ForwardedRef<HTMLUListElement>
) {
  const { ownerState, ...other } = props
  const popupContext = useContext(PopupContext)

  if (popupContext == null) {
    throw new Error(
      'The `AnimatedListbox` component cannot be rendered outside a `Popup` component'
    )
  }

  const verticalPlacement = popupContext.placement.split('-')[0]

  return (
    <CssTransition
      className={`placement-${verticalPlacement}`}
      enterClassName='open'
      exitClassName='closed'
    >
      <ul {...other} ref={ref} />
    </CssTransition>
  )
})

AnimatedListBox.displayName = 'AnimatedListBox'

export { AnimatedListBox }
