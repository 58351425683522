import { cva, VariantProps } from 'class-variance-authority'
import { ButtonProps } from '@mui/base'

export const button = cva(
  [
    'rounded-lg box-shadow cursor-pointer',
    'disabled:opacity-50 disabled:cursor-not-allowed',
  ],
  {
    variants: {
      variant: {
        contained: ['ripple-bg-primary-600', 'text-white'],
        text: ['!p-0'],
        outlined: [
          'border border-neutral-700 shadow-sm text-m text-neutral-950',
        ],
      },
      size: {
        medium: ['py-[10px] px-[16px]'],
        large: ['py-[12px] px-[18px]'],
        small: ['py-[8px] px-[14px]'],
        xsmall: ['py-[4px] px-[10px]'],
      },
    },
    defaultVariants: {
      variant: 'contained',
      size: 'medium',
    },
  }
)

export interface ButtonPropsInterface
  extends ButtonProps,
    VariantProps<typeof button> {}
