import {
  AgreementCreateScreen,
  AgreementListScreen,
} from '@/features/agreements/resources/views'
import {
  IntegrationListScreen,
  IntegrationsCreateScreen,
  IntegrationsEditScreen,
} from '@/features/integrations/resources/views'
import { PrivateRouterInterface } from '@/utils/routes/privateRoutes/types'
import { DashboardScreen } from '@/features/dashboard/resources/views'
import {
  UserCreateScreen,
  UserDetailsScreen,
  UserEditScreen,
  UserListScreen,
} from '@/features/users/resources/views'
import { PermissionEnum } from '@/features/permissions/redux/enums/permissionEnum'
import {
  CategoryCreateScreen,
  CategoryDetailsScreen,
  CategoryEditScreen,
  CategoryListScreen,
} from '@/features/categories/resources/views'
import {
  ProductCreateScreen,
  ProductEditScreen,
  ProductListScreen,
} from '@/features/products/resources/views'
import {
  RolesCreateScreen,
  RolesEditScreen,
  RolesListScreen,
} from '@/features/roles/resources/views'
import {
  PostListScreen,
  PostsEditScreen,
} from '@/features/posts/resources/views'

export const routes: PrivateRouterInterface[] = [
  {
    path: '/',
    component: DashboardScreen,
    permissions: [],
  },
  {
    path: '/users',
    component: UserListScreen,
    permissions: [PermissionEnum.USER_VIEW],
  },
  {
    path: '/users/:id',
    component: UserDetailsScreen,
    permissions: [PermissionEnum.USER_VIEW],
  },
  {
    path: '/users/create',
    component: UserCreateScreen,
    permissions: [PermissionEnum.USER_SAVE],
  },
  {
    path: '/users/:id/edit',
    component: UserEditScreen,
    permissions: [PermissionEnum.USER_SAVE, PermissionEnum.USER_VIEW],
  },
  {
    path: '/categories',
    component: CategoryListScreen,
    permissions: [PermissionEnum.CATEGORY_VIEW],
  },
  {
    path: '/categories/create',
    component: CategoryCreateScreen,
    permissions: [PermissionEnum.CATEGORY_SAVE],
  },
  {
    path: '/categories/:id/edit',
    component: CategoryEditScreen,
    permissions: [PermissionEnum.CATEGORY_SAVE, PermissionEnum.CATEGORY_VIEW],
  },
  {
    path: '/categories/:id',
    component: CategoryDetailsScreen,
    permissions: [PermissionEnum.CATEGORY_VIEW],
  },
  {
    path: '/products',
    component: ProductListScreen,
    permissions: [PermissionEnum.PRODUCT_VIEW],
  },
  {
    path: '/products/create',
    component: ProductCreateScreen,
    permissions: [PermissionEnum.PRODUCT_SAVE],
  },
  {
    path: '/products/:id/edit',
    component: ProductEditScreen,
    permissions: [PermissionEnum.PRODUCT_SAVE, PermissionEnum.PRODUCT_VIEW],
  },
  {
    path: '/agreements/create',
    component: AgreementCreateScreen,
    permissions: [PermissionEnum.AGREEMENT_SAVE, PermissionEnum.AGREEMENT_VIEW],
  },
  {
    path: '/agreements',
    component: AgreementListScreen,
    permissions: [PermissionEnum.AGREEMENT_VIEW],
  },
  {
    path: '/roles/create',
    component: RolesCreateScreen,
    permissions: [PermissionEnum.ROLE_VIEW, PermissionEnum.ROLE_SAVE],
  },
  {
    path: '/roles/:id/edit',
    component: RolesEditScreen,
    permissions: [PermissionEnum.ROLE_VIEW, PermissionEnum.ROLE_SAVE],
  },
  {
    path: '/roles',
    component: RolesListScreen,
    permissions: [PermissionEnum.ROLE_VIEW],
  },
  {
    path: '/posts',
    component: PostListScreen,
    permissions: [PermissionEnum.POST_VIEW],
  },
  {
    path: '/posts/:id/edit',
    component: PostsEditScreen,
    permissions: [PermissionEnum.POST_VIEW, PermissionEnum.POST_SAVE],
  },
  {
    path: '/integrations/create',
    component: IntegrationsCreateScreen,
    permissions: [
      PermissionEnum.INTEGRATION_SAVE,
      PermissionEnum.INTEGRATION_VIEW,
    ],
  },
  {
    path: '/integrations/:id/edit',
    component: IntegrationsEditScreen,
    permissions: [
      PermissionEnum.INTEGRATION_SAVE,
      PermissionEnum.INTEGRATION_VIEW,
    ],
  },
  {
    path: '/integrations',
    component: IntegrationListScreen,
    permissions: [PermissionEnum.INTEGRATION_VIEW],
  },
]
