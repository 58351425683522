import { forwardRef } from 'react'
import { cn } from '../../utils/helpers/cn'
import { CardTypes } from './Card.types'

const Card = forwardRef<HTMLDivElement, CardTypes>((props, ref) => {
  const { className, noPadding = false, ...rest } = props

  const classNames = cn(
    className,
    'py-4.3 px-6 border border-neutral-600 shadow-sm rounded-md bg-white mb-8',
    {
      '!p-0': noPadding,
    }
  )

  return <div ref={ref} className={classNames} {...rest} />
})

Card.displayName = 'Card'

export { Card }
