import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Form } from '../../components'
import { SaveIntegrationRequestInterface } from '@/features/integrations/redux/types'
import { useStoreIntegrationMutation } from '@/features/integrations/redux/integrationAPI'
import { Card } from '@/components'

export const IntegrationsCreateScreen = (): React.ReactNode => {
  const { t } = useTranslation(['integrations'])
  const [storeIntegration] = useStoreIntegrationMutation()
  const navigate = useNavigate()

  const onSubmit = async (data: SaveIntegrationRequestInterface) => {
    try {
      await storeIntegration(data).unwrap()
      toast.success(t('integrations:create.success'))
      navigate('/integrations')
    } catch (error) {
      toast.error(t('utils:errors.something_went_wrong'))
    }
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <span className={'text-2xl font-medium'}>
        {t('integrations:create.title')}
      </span>
      <Card>
        <Form onSubmit={onSubmit} />
      </Card>
    </div>
  )
}
