import { useNavigate, useParams } from 'react-router-dom'
import * as React from 'react'
import { useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { UpdateUserValidation } from './rules/UpdateUserValidation'
import {
  useGetUserQuery,
  useUpdateUserMutation,
} from '@/features/users/redux/userAPI'
import {
  StoreUserPropsInterface,
  UpdateUserPropsInterface,
} from '@/features/users/redux/types'
import { BackendValidationErrorInterface } from '@/utils/hooks/useValidation'
import { isFetchBaseQueryError } from '@/utils/api/rtkHelper'
import { Card } from '@/components'
import { Form } from '@/features/users/resources/components'

export const UserEditScreen = (): React.ReactNode => {
  const { id } = useParams<{ id: string }>() as { id: string }
  const { data } = useGetUserQuery(id)
  const [errors, setErrors] =
    useState<BackendValidationErrorInterface<UpdateUserPropsInterface>>()
  const { t } = useTranslation(['users'])
  const [updateUser] = useUpdateUserMutation()
  const navigate = useNavigate()

  const onSubmit = async (
    data: StoreUserPropsInterface | UpdateUserPropsInterface
  ) => {
    const transformedData = _.omitBy(data, _.isNil) as UpdateUserPropsInterface

    try {
      await updateUser({
        id,
        ...transformedData,
      }).unwrap()
      toast.success(t('users:edit.updated'))
      navigate('/users')
    } catch (error) {
      if (isFetchBaseQueryError(error) && error.status === 422) {
        setErrors(
          error.data as BackendValidationErrorInterface<UpdateUserPropsInterface>
        )
      }
    }
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <span className={'text-2xl font-medium'}>{t('users:edit.title')}</span>
      <Card>
        <Form
          data={data}
          backendErrors={errors}
          validation={UpdateUserValidation}
          onSubmit={onSubmit}
        />
      </Card>
    </div>
  )
}
