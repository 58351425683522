import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl } from '@mui/base'
import _ from 'lodash'
import { AsyncPaginate } from 'react-select-async-paginate'
import { SingleValue } from 'react-select'
import { FormTypes } from './Form.types'
import {
  StoreUserPropsInterface,
  UpdateUserPropsInterface,
} from '@/features/users/redux/types'
import { useLazyGetRolesQuery } from '@/features/roles/redux/roleAPI'
import { useValidation } from '@/utils/hooks/useValidation'
import { useAsyncPaginated } from '@/utils/hooks/useAsyncPaginated'
import { RoleInterface } from '@/features/roles/redux/types'
import { Button, FormHelperText, TextInput } from '@/components'
import { Select2OptionInterface } from '@/app/types'

const Form: React.FC<FormTypes> = ({
  data,
  backendErrors,
  onSubmit,
  validation,
}): React.ReactNode => {
  const { t } = useTranslation(['form', 'validation'])
  const { schema, defaultValues, resolveValidationErrors } = useValidation(
    new validation(),
    t
  )
  const [fetchRoles] = useLazyGetRolesQuery()
  const { handleFetch, classes } = useAsyncPaginated<RoleInterface>(
    fetchRoles,
    'name',
    'id',
    'name'
  )

  const { control, setValue, setError, handleSubmit } = useForm<
    StoreUserPropsInterface | UpdateUserPropsInterface
  >({
    resolver: yupResolver(schema),
    defaultValues,
  })
  const [selectedOption, setSelectedOption] = useState<Select2OptionInterface>()

  useEffect(() => {
    if (backendErrors) {
      const errors = resolveValidationErrors(backendErrors)
      _.each(errors, (value, key) => {
        setError(key as keyof StoreUserPropsInterface, {
          type: 'manual',
          message: value,
        })
      })
    }
  }, [setError, backendErrors])

  useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue('email', data.email)
      setValue('role_id', data.role?.id)
    }
  }, [data])

  const handleChangeRole = (option: SingleValue<Select2OptionInterface>) => {
    if (option) {
      setSelectedOption(option)
      setValue('role_id', +option.value)
    }
  }

  return (
    <div className={'flex flex-col gap-y-6'}>
      <div className={'grid grid-cols-4 2xl:grid-cols-6 gap-4'}>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl {...field} error={!!error}>
              <TextInput label={t('form:labels.name')} name={field.name} />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
          name={'name'}
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl {...field} error={!!error}>
              <TextInput
                type={'email'}
                label={t('form:labels.email')}
                name={field.name}
              />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
          name={'email'}
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl {...field} error={!!error}>
              <TextInput
                type={'password'}
                label={t('form:labels.password')}
                name={field.name}
              />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
          name={'password'}
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl {...field} error={!!error}>
              <TextInput
                type={'password'}
                label={t('form:labels.password_confirmation')}
                name={field.name}
              />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
          name={'password_confirmation'}
          control={control}
        />
        <Controller
          render={({ fieldState: { error } }) => (
            <FormControl error={!!error}>
              <AsyncPaginate
                value={selectedOption}
                loadOptions={handleFetch}
                classNames={classes}
                onChange={handleChangeRole}
              />
            </FormControl>
          )}
          name={'role_id'}
          control={control}
        />
      </div>
      <div className={'flex'}>
        <Button
          variant={'contained'}
          type={'button'}
          onClick={handleSubmit(onSubmit)}
        >
          {t('form:buttons.save')}
        </Button>
      </div>
    </div>
  )
}

export { Form }
