import { TFunction } from 'i18next'
import * as yup from 'yup'
import { StoreCategoryPropsInterface } from '@/features/categories/redux/types'
import { ValidationInterface } from '@/utils/hooks/useValidation/useValidation.types'

class StoreCategoryValidation
  implements ValidationInterface<StoreCategoryPropsInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<StoreCategoryPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      name: yup.string().required(t('validation:required')),
      parent_id: yup.number().nullable(),
      icon: yup.mixed<File>().required(t('validation:required')),
    })
  }

  defaultValues(): StoreCategoryPropsInterface {
    return {
      name: '',
      parent_id: null,
      icon: null,
    }
  }
}

export { StoreCategoryValidation }
