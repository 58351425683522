import { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react'
import { Select as BaseSelect } from '@mui/base/Select'
import { Label } from '../Label'
import { SelectTypes } from './Select.types'
import { Button } from './Button'
import { AnimatedListBox } from './AnimatedListBox'
import { cn } from '@/utils/helpers/cn'

const resolveSlotProps = (fn: any, args: any) =>
  typeof fn === 'function' ? fn(args) : fn

const Select = forwardRef(function CustomSelect<
  TValue extends string | number,
  Multiple extends boolean
>(props: SelectTypes<TValue, Multiple>, ref: ForwardedRef<HTMLButtonElement>) {
  const { label, ...rest } = props
  const rootRef = useRef<HTMLButtonElement>(null)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (rootRef.current) {
      setWidth(rootRef.current.offsetWidth)
    }
  }, [])

  return (
    <div className={'flex w-full flex-col gap-y-4'}>
      {label && <Label label={label} />}
      <BaseSelect
        ref={ref}
        {...rest}
        slots={{ root: Button, listbox: AnimatedListBox }}
        className={props.className}
        slotProps={{
          ...props.slotProps,
          root: (ownerState) => {
            const resolvedSlotProps = resolveSlotProps(
              props.slotProps?.root,
              ownerState
            )

            return {
              ...resolvedSlotProps,
              ref: rootRef,
              className: cn(
                'relative rounded-[7px] border border-opaque bg-white px-6 py-4 text-left font-work-sans text-[12px] leading-[20px] transition-all ease-in-out',
                '[&>svg]:absolute [&>svg]:right-2.5 [&>svg]:top-0 [&>svg]:h-full [&>svg]:text-base',
                resolvedSlotProps?.className
              ),
            }
          },
          listbox: (ownerState) => {
            const resolvedSlotProps = resolveSlotProps(
              props.slotProps?.listbox,
              ownerState
            )

            return {
              ...resolvedSlotProps,
              className: cn(
                'mt-2 max-h-[192px] overflow-auto rounded-[7px] border border-opaque bg-content-inverse-primary font-work-sans',
                'transition-[opacity,transform] [.closed_&]:scale-90 [.closed_&]:opacity-0 [.open_&]:scale-100 [.open_&]:opacity-100 [.placement-bottom_&]:origin-top [.placement-top_&]:origin-bottom'
              ),
              style: {
                width: width,
                ...resolvedSlotProps?.style,
              },
            }
          },
          popup: (ownerState) => {
            const resolvedSlotProps = resolveSlotProps(
              props.slotProps?.popup,
              ownerState
            )

            return {
              ...resolvedSlotProps,
            }
          },
        }}
      />
    </div>
  )
})

Select.displayName = 'Select'

export { Select }
