import { TFunction } from 'i18next'
import * as yup from 'yup'
import { ValidationInterface } from '@/utils/hooks/useValidation/useValidation.types'
import { UnitEnum } from '@/app/enums/unitEnum'
import { UpdatePostRequestInterface } from '@/features/posts/redux/types'

class UpdatePostValidation
  implements ValidationInterface<UpdatePostRequestInterface>
{
  rules(
    t: TFunction
  ): yup.ObjectSchema<UpdatePostRequestInterface, yup.AnyObject> {
    return yup.object().shape({
      product_id: yup.number().required(t('validation:required')),
      category_id: yup.number().required(t('validation:required')),
      price: yup
        .number()
        .required(t('validation:required'))
        .min(0.01, t('validation:min', { min: 0.01 })),
      description: yup
        .string()
        .required(t('validation:required'))
        .min(20, t('validation:field_min_length', { length: 20 })),
      unit: yup.mixed<UnitEnum>().required(t('validation:required')),
      title: yup.string().required(t('validation:required')),
      quantity: yup
        .number()
        .required(t('validation:required'))
        .min(1, t('validation:min', { min: 1 })),
      location_name: yup.string().required(t('validation:required')),
      location: yup.object().shape({
        lat: yup.number().required(t('validation:required')),
        lng: yup.number().required(t('validation:required')),
      }),
      production_place: yup.string().required(t('validation:required')),
      harvest_date: yup.string().required(t('validation:required')),
      is_prow_wps: yup.boolean().required(t('validation:required')),
      address: yup
        .object()
        .shape({
          address: yup.string().required(t('validation:required')),
          city: yup.string().required(t('validation:required')),
          zip_code: yup.string().required(t('validation:required')),
        })
        .required(),
      expired_at: yup.string().nullable(),
      images: yup
        .array()
        .of(yup.mixed<File>().required())
        .max(1, t('validation:max_images', { max: 1 }))
        .nullable(),
    })
  }

  defaultValues(): UpdatePostRequestInterface {
    return {
      product_id: 0,
      category_id: 0,
      location_name: '',
      location: {
        lat: 0,
        lng: 0,
      },
      title: '',
      description: '',
      price: 0,
      unit: UnitEnum.KILOGRAM,
      quantity: 0,
      production_place: '',
      harvest_date: '',
      is_prow_wps: false,
      address: {
        address: '',
        city: '',
        zip_code: '',
      },
      expired_at: '',
    }
  }
}

export { UpdatePostValidation }
