import { Fragment, ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl } from '@mui/base'
import { toast } from 'react-toastify'
import { ResetPasswordValidation } from '../validations/ResetPasswordValidation'
import { ResetPasswordPropsInterface } from '@/features/auth/redux/types'
import { useAppDispatch } from '@/app/hooks'
import { resetPasswordMutation } from '@/features/auth/redux/authAPI'
import { Button, FormHelperText, TextInput } from '@/components'
import { useValidation } from '@/utils/hooks/useValidation'

const ResetPasswordScreen = (): ReactNode => {
  const { t } = useTranslation(['auth', 'validation', 'form'])
  const [params] = useSearchParams()
  const { schema, defaultValues } = useValidation(
    new ResetPasswordValidation(),
    t
  )
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    if (errors.token) {
      toast.error(t('auth:reset_password.error'))
    }
  }, [errors])

  useEffect(() => {
    if (params.get('token')) {
      setValue('token', params.get('token')!)
    }

    if (params.get('email')) {
      setValue('email', params.get('email')!)
    }
  }, [params, setValue])

  const onSubmit = (data: ResetPasswordPropsInterface) => {
    dispatch(resetPasswordMutation(data))
      .unwrap()
      .then(() => {
        toast.success(t('auth:reset_password.success'))
        navigate('/login')
      })
      .catch(() => toast.error(t('auth:reset_password.error')))
  }

  return (
    <Fragment>
      <div className={'flex flex-col gap-y-6'}>
        <Controller
          name={'email'}
          control={control}
          render={({ field }) => (
            <FormControl {...field} error={!!errors.email}>
              <TextInput
                name={field.name}
                label={t('form:labels.email')}
                type={'email'}
                placeholder={t('form:placeholders.email')}
              />
              <FormHelperText message={errors.email?.message} />
            </FormControl>
          )}
        />
        <Controller
          name={'password'}
          control={control}
          render={({ field }) => (
            <FormControl {...field} error={!!errors.password}>
              <TextInput
                name={field.name}
                label={t('form:labels.password')}
                type={'password'}
                placeholder={t('form:placeholders.password')}
              />
              <FormHelperText message={errors.password?.message} />
            </FormControl>
          )}
        />
        <Controller
          name={'password_confirmation'}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl {...field} error={!!error}>
              <TextInput
                name={field.name}
                label={t('form:labels.password_confirmation')}
                type={'password'}
                placeholder={t('form:placeholders.password_confirmation')}
              />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
        />
        <Button variant={'contained'} onClick={handleSubmit(onSubmit)}>
          {t('auth:reset_password.button')}
        </Button>
      </div>
    </Fragment>
  )
}

export { ResetPasswordScreen }
