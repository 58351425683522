import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl } from '@mui/base'
import { FormTypes } from './Form.types'
import {
  StoreCategoryPropsInterface,
  UpdateCategoryPropsInterface,
} from '@/features/categories/redux/types'
import { Select2OptionInterface } from '@/app/types'
import { useValidation } from '@/utils/hooks/useValidation'
import {
  Button,
  FormHelperText,
  Label,
  SearchCategory,
  TextInput,
} from '@/components'

export const Form: React.FC<FormTypes> = ({
  data,
  onSubmit,
  validation,
}): React.ReactNode => {
  const { t } = useTranslation(['form', 'validation'])
  const { schema, defaultValues } = useValidation(new validation(), t)
  const [selectedParenCategory, setSelectedParentCategory] = useState<
    Select2OptionInterface | undefined
  >()

  const { control, handleSubmit, setValue } = useForm<
    StoreCategoryPropsInterface | UpdateCategoryPropsInterface
  >({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (data) {
      if (data.parent_id && data.parent) {
        setValue('parent_id', data.parent_id)

        setSelectedParentCategory({
          label: data.parent?.name,
          value: data.parent_id.toString(),
        })
      }

      setValue('name', data.name)
    }
  }, [data])

  return (
    <div className={'flex flex-col gap-y-6'}>
      <div className={'grid grid-cols-3 2xl:grid-cols-4 gap-4'}>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl {...field} error={!!error}>
              <TextInput name={field.name} label={t('form:labels.name')} />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
          name={'name'}
          control={control}
        />
        <Controller
          render={({ field }) => (
            <div className={'flex flex-col'}>
              <Label label={t('form:labels.parent_category')} />
              <SearchCategory
                onChange={(value) => field.onChange(value?.value)}
                isMulti={false}
                defaultValue={selectedParenCategory}
              />
            </div>
          )}
          name={'parent_id'}
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl error={!!error} className={'flex flex-col gap-y-1'}>
              <Label label={t('form:labels.icon')} />
              <input
                type={'file'}
                accept={'image/*'}
                name={field.name}
                onChange={(e) => {
                  e.target.files
                    ? field.onChange(e.target.files[0])
                    : field.onChange(null)
                }}
              />
              <FormHelperText message={error?.message} />
            </FormControl>
          )}
          name={'icon'}
          control={control}
        />
      </div>
      <div className={'flex'}>
        <Button variant={'contained'} onClick={handleSubmit(onSubmit)}>
          {t('form:buttons.save')}
        </Button>
      </div>
    </div>
  )
}
