import { FC, ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControl } from '@mui/base'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import { SaveIntegrationValidation } from './rules/SaveIntegrationValidation'
import { FormInterface } from '@/app/types'
import {
  IntegrationInterface,
  SaveIntegrationRequestInterface,
} from '@/features/integrations/redux/types'
import {
  integrationPermissions,
  PermissionEnum,
} from '@/features/permissions/redux/enums/permissionEnum'
import {
  Button,
  Checkbox,
  CreateableSelect,
  FormHelperText,
  Label,
  TextInput,
} from '@/components'
import { useValidation } from '@/utils/hooks/useValidation'

const Form: FC<
  FormInterface<SaveIntegrationRequestInterface, IntegrationInterface>
> = ({ data, onSubmit }): ReactNode => {
  const { t } = useTranslation(['form', 'utils', 'validation'])
  const { schema, defaultValues } = useValidation(
    new SaveIntegrationValidation(),
    t
  )

  const { control, handleSubmit, setValue, watch } =
    useForm<SaveIntegrationRequestInterface>({
      defaultValues,
      resolver: yupResolver(schema),
    })

  const watchPermissions = watch('permissions')

  useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue('ip_addresses', data.ip_addresses)
      setValue('token', data.token)

      if (data.permissions) {
        setValue(
          'permissions',
          data.permissions.map((p) => p.name)
        )
      }
    }
  }, [data])

  const handleGenerateToken = () => {
    setValue('token', uuid())
  }

  const handleChangePermission = (permission: PermissionEnum) => {
    const permissions = watchPermissions

    if (permissions.includes(permission)) {
      setValue(
        'permissions',
        permissions.filter((p: PermissionEnum) => p !== permission)
      )
    } else {
      setValue('permissions', [...permissions, permission])
    }
  }

  return (
    <div className={'flex flex-col gap-y-6'}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={'flex flex-col gap-y-4'}
      >
        <div className={'grid grid-cols-3 2xl:grid-cols-4 gap-4'}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl {...field} error={!!error}>
                <TextInput label={t('form:labels.name')} />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={'name'}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl className={'flex flex-col'} error={!!error}>
                <Label label={t('form:labels.ip_addresses')} />
                <CreateableSelect
                  value={field.value.map((value) => ({
                    value,
                    label: value,
                  }))}
                  isMulti={true}
                  onChange={(value) =>
                    setValue(
                      'ip_addresses',
                      value.map((v) => v.value)
                    )
                  }
                />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={'ip_addresses'}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl {...field} error={!!error}>
                <TextInput
                  label={t('form:labels.token')}
                  endAdornment={
                    <FontAwesomeIcon
                      icon={faRefresh}
                      className={'cursor-pointer'}
                      onClick={handleGenerateToken}
                    />
                  }
                />
                <FormHelperText message={error?.message} />
              </FormControl>
            )}
            name={'token'}
            control={control}
          />
        </div>
        <div className={'flex flex-col gap-y-1'}>
          {_.map(integrationPermissions, (permission, index) => (
            <Checkbox
              onChange={() => handleChangePermission(permission)}
              key={index}
              checked={watchPermissions.includes(permission)}
              label={t(`utils:permissions.${permission}`)}
            />
          ))}
        </div>
        <div className={'flex'}>
          <Button variant={'contained'} type={'submit'}>
            {t('form:buttons.save')}
          </Button>
        </div>
      </form>
    </div>
  )
}

export { Form }
