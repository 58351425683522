import { SelectRootSlotProps } from '@mui/base'
import { ForwardedRef, forwardRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

const Button = forwardRef(function Button<
  TValue extends NonNullable<unknown>,
  Multiple extends boolean
>(
  props: SelectRootSlotProps<TValue, Multiple>,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const { ownerState, ...other } = props
  return (
    <button type='button' {...other} ref={ref}>
      {other.children}
      {ownerState.open ? (
        <FontAwesomeIcon icon={faChevronUp} />
      ) : (
        <FontAwesomeIcon icon={faChevronDown} />
      )}
    </button>
  )
})

export { Button }
