import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { CompanyData, PersonalData, RoleData } from './components'
import {
  useDeleteUserMutation,
  useGetUserQuery,
} from '@/features/users/redux/userAPI'
import { usePermissions } from '@/utils/hooks/usePermission/usePermission'
import { PermissionEnum } from '@/features/permissions/redux/enums/permissionEnum'
import { Button, ButtonLink } from '@/components'

export const UserDetailsScreen = (): React.ReactNode => {
  const { id } = useParams<{ id: string }>() as { id: string }
  const { data } = useGetUserQuery(id)
  const { hasPermission } = usePermissions()
  const { t } = useTranslation(['users', 'form'])
  const [deleteUser] = useDeleteUserMutation()
  const navigate = useNavigate()

  if (!data) {
    return <div>Loading...</div>
  }

  const handleDelete = async () => {
    try {
      await deleteUser(id).unwrap()
      toast.success(t('users:list.deleted'))
      navigate('/users')
    } catch (error) {
      // block empty
    }
  }

  return (
    <div className={'flex flex-col p-4 gap-y-8'}>
      <div className={'flex justify-between items-center'}>
        <h3 className={'text-2xl font-medium'}>
          {t('users:show.title', { name: data.name })}
        </h3>
        <div className={'flex gap-x-2'}>
          {hasPermission(PermissionEnum.USER_SAVE) && (
            <ButtonLink to={`/users/${id}/edit`} variant={'contained'}>
              {t('form:buttons.edit')}
            </ButtonLink>
          )}
          {hasPermission(PermissionEnum.USER_DELETE) && (
            <Button
              variant={'contained'}
              onClick={handleDelete}
              className={'bg-red-600'}
            >
              {t('form:buttons.delete')}
            </Button>
          )}
        </div>
      </div>
      <div className={'grid grid-cols-2 2xl:grid-cols-3 gap-4'}>
        <PersonalData {...data} />
        {data.company && <CompanyData company={data.company} />}
        {data.role && <RoleData role={data.role} />}
      </div>
    </div>
  )
}
