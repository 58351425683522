import _, { isArray } from 'lodash'
import * as React from 'react'
import { AsyncSelect } from './AsyncSelect'
import { SearchProps } from './AsyncSelect.types'
import { Query } from '@/utils/query'
import { useLazySearchCategoriesQuery } from '@/features/categories/redux/categoryAPI'

const SearchCategory: React.FC<SearchProps> = ({
  defaultValue,
  onChange,
  isMulti,
  query: _query,
}): React.ReactNode => {
  const [searchCategories] = useLazySearchCategoriesQuery()
  const _handleSearchCategory = async (value: string) => {
    let query = new Query()

    if (!_query) {
      query.where('name', value)

      if (defaultValue && !isArray(defaultValue))
        query.whereNotIn('id', [defaultValue.value])
    } else {
      query = _query
    }

    const response = await searchCategories(query.url()).unwrap()

    return response.map((category) => ({
      label: category.name,
      value: category.id.toString(),
    }))
  }

  const handleSearchCategory = _.debounce(_handleSearchCategory, 500)

  return (
    <AsyncSelect
      isMulti={isMulti}
      cacheOptions
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={onChange}
      value={defaultValue}
      loadOptions={handleSearchCategory}
    />
  )
}

export { SearchCategory }
