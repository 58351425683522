import { FC } from 'react'
import { LabelTypeInterface } from './Label.types'
import { cn } from '@/utils/helpers/cn'

const Label: FC<LabelTypeInterface> = ({ label, className, ...rest }) => {
  const classNames = cn('font-s text-gray-700 my-2', className)

  return (
    <label className={classNames} {...rest}>
      {label}
    </label>
  )
}

export { Label }
